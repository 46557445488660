// Modules
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

// PropTypes
const propTypes = { children: PropTypes.node.isRequired }
const Container = ({ children }) => {
  const theme = useTheme()
  const ContainerStyles = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 ${theme.space.betweenColumns}; ;
  `

  return (
    <ContainerStyles>{children}</ContainerStyles>
  )
}

Container.propTypes = propTypes
export default Container
