const breakpoints = {
  large: [1025, 1440],
  largeUp: [1025],
  medium: [641, 1024],
  mediumDown: [1024],
  mediumUp: [641],
  small: [0, 640],
}

Object.keys(breakpoints).map(key => (
  breakpoints[key] = breakpoints[key].length !== 2
    ? key.includes('Up')
      ? `@media screen and (min-width: ${breakpoints[key][0]}px)`
      : `@media screen and (max-width: ${breakpoints[key][0]}px)`
    : `@media screen and (min-width: ${breakpoints[key][0]}px) and (max-width: ${breakpoints[key][1]}px)`
))

const { small } = breakpoints,
    { medium } = breakpoints,
    { mediumUp } = breakpoints,
    { mediumDown } = breakpoints,
    { large } = breakpoints,
    { largeUp } = breakpoints

export {
  small,
  medium,
  mediumUp,
  mediumDown,
  large,
  largeUp,
}
