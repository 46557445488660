// Modules
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

// Components
import Container from './Container'

// Scripts
import {
  largeUp,
  mediumUp,
} from 'scripts/media-query'

// PropTypes
const propTypes = {
  data: PropTypes.shape({
    adText: PropTypes.string.isRequired,
    backgroundColor: PropTypes.objectOf(PropTypes.string).isRequired,
    buttonText: PropTypes.string.isRequired,
    fontColor: PropTypes.objectOf(PropTypes.string).isRequired,
  }),
}

const AdBanner = ({ data }) => {
  const {
    adText,
    buttonText,
  } = data

  const theme = useTheme()
  const background = data?.backgroundColor
    ? data?.backgroundColor?.customColor
      ? data.backgroundColor.customColor
      : data.backgroundColor.selectedColorOption
    : theme.color.white

  const fontColor = data?.fontColor
    ? data?.fontColor?.customColor
      ? data.fontColor.customColor
      : data.fontColor.selectedColorOption
    : theme.color.black

  const AdBannerStyles = styled.div`
    width: 100%;
    background-color: ${background};
    color: ${fontColor};

    > a { display: block; }
    .banner-content-wrapper { padding: .75rem 0; }

    .banner-text {
      font-size: .75rem;
      text-align: center;
      margin: 0;

      .learn-more { text-decoration: underline; }
    }
  `

  return (
    <AdBannerStyles>
      <Link to='/contact?cr=deal'>
        <Container>
          <div className='banner-content-wrapper'>
            <p className='banner-text'>
              {adText} <span className='learn-more'>{buttonText}</span>
            </p>
          </div>
        </Container>
      </Link>
    </AdBannerStyles>
  )
}

AdBanner.propTypes = propTypes
export default AdBanner
