// Modules
import styled from '@emotion/styled'

// Scripts
import { largeUp } from './media-query'
import theme from './theme'

const AppBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100vh;
  background-color: ${theme.color.white};
  color: ${theme.color.darkGrey};

  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    &.no-hero {
      padding: 10rem 0 0;

      ${largeUp} {
        padding: 12rem 0 0;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  a {
    &:active,
    &:focus,
    &:hover {
      color: ${theme.color.primary};
    }
  }

  a.button,
  button.button {
    padding: 1rem 1.5rem;
    text-align: center;
    font-size: 1.15rem;
    font-weight: 700;
    margin: 0 auto;
    color: ${theme.color.white};
    border-radius: 5px;

    &.left { margin: 0 auto 0 0; }
    &.center { margin: 0 auto; }
    &.right { margin: 0 0 0 auto; }
    &.full { width: 100%; }
    &.primary { background-color: ${theme.color.primary}; }
    &.success { background-color: ${theme.color.success}; }
    &.warn { background-color: ${theme.color.warn}; }
    &.fail { background-color: ${theme.color.fail}; }
    &:disabled {
      background-color: ${theme.color.lightGrey};
      cursor: not-allowed;
    }
  }

  .section-space { margin: ${theme.space.betweenSections} 0; }
  .no-hero-space { margin-top: 164px; }
  .top-section-space { margin-top: ${theme.space.betweenSections}; }
  .bottom-section-space { margin-bottom: ${theme.space.betweenSections}; }
`

export default AppBody
