// Modules
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import React, {
  useContext,
  useState,
} from 'react'

// Context
import { BreakpointContext } from 'Context/breakpointContext'

// Data
import headerData from 'data/headerNav'

// Hooks
import { useScrollPast } from 'hooks/UseScrollPast'

// Scripts
import {
  largeUp,
  mediumDown,
} from 'scripts/media-query'

// Variables
const mobileLogoSize = '50px',
    scrolledLogoSize = '65px',
    unscrolledLogoSize = '125px'

const HeaderStyles = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  overflow: hidden;
  overflow-anchor: none;
  transition: all 0.5s ease-out, background .8s ease-out;
  transition-delay: 0.2s;
  z-index: 10;

  &[data-hero=false],
  &[data-hero=false].not-scrolled,
  &[data-hero=false].has-scrolled {
    position: absolute;
    transition: none;
    background-color: ${props => props.theme.color.darkGrey};
    box-shadow: none;
  }

  &[data-banner=true]:not([data-hero=false]) {
    position: absolute;

    &.has-scrolled { position: fixed; }
  }

  &.not-scrolled {
    background-color: ${props => props.theme.color.darkGrey};

    ${largeUp} {
      background-color: transparent;
    }
  }

  &.has-scrolled {
    background-color: ${props => props.theme.color.darkGrey};
    box-shadow: 0 0 15px ${props => props.theme.color.darkGrey};

    ${largeUp} {
      .header-partial .logo-wrapper a .logo {
        width: ${scrolledLogoSize};
      }

      .menu {        
        .phone-number { color: ${props => props.theme.color.primary}; }

        a {
          &:active,
          &:focus,
          &:hover {
            color: ${props => props.theme.color.primary};
          }
        }
      }
    }
  }

  .header-partial {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px ${props => props.theme.space.betweenColumns};
    z-index: 9;

    .logo-wrapper a {
      display: flex;
      align-items: center;
      color: ${props => props.theme.color.white};
      font-size: 12px;

      span { ${largeUp} { display: none; } }

      .logo {
        width: ${mobileLogoSize};
        height: auto;
        margin-right: 10px;

        ${largeUp} {
          width: ${unscrolledLogoSize};
          transition: all 0.5s ease-out;
        }
      }
    }

    ${largeUp} { padding: 0; }
  }
  
  .hamburger-container {
    position: relative;
    display: inline-block;
    transform: rotate(0deg);
    transition: all 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    .hamburger {
      display: block;
      position: relative;
      width: 30px;
      height: 30px;
      padding: 0;
      background-color: transparent;

      .bar {
        display: block;
        position: relative;
        width: 100%;
        height: 3px;
        background-color: ${props => props.theme.color.white};
        transition: all 0.3s;
        transition-delay: 0s;

        &.topBar { transform: translateY(-5px) rotate(0deg); }
        &.midBar { transform: scale(1); }
        &.btmBar { transform: translateY(5px) rotate(0deg); }
      }
    }

    ${largeUp} { display: none; }
  }

  nav {
    overflow-y: auto;

    ${largeUp} { overflow-y: none; }
  }
  
  .menu {
    display: block;
    position: relative;
    padding: ${props => props.theme.space.betweenRows} ${props => props.theme.space.betweenColumns};
    list-style: none;

    .menu-item {
      margin-top: 5px;
      border-bottom: 1px solid ${props => props.theme.color.lightGrey};
      opacity: 0;
      transform: translateY(-50px);
      transition: transform 0.5s, opacity 0.6s;

      &:nth-of-type(1) { transition-delay: 0.5s; }
      &:nth-of-type(2) { transition-delay: 0.45s; }
      &:nth-of-type(3) { transition-delay: 0.4s; }
      &:nth-of-type(4) { transition-delay: 0.35s; }
      &:nth-of-type(5) { transition-delay: 0.3s; }
      &:nth-of-type(6) { transition-delay: 0.25s; }

      &.phone-number-container {
        border: none;

        ${mediumDown} {
          margin-top: 1.5rem;
        }
      }

      ${largeUp} {
        margin: 0 ${props => props.theme.space.betweenColumns};
        opacity: 1;
        transform: translateY(0);
        border: none;

        &:last-of-type {
          margin-right: 0;
        }
      }

      @media screen and (min-width: 1024px) and (max-width: 1146px) {
        margin: 0 2vw;
      }
    }

    a,
    .phone-number {
      display: block;
    }

    a {
      position: relative;
      padding: 12px 0;
      color: ${props => props.theme.color.white};
      font-size: 1.2rem;

      &:active,
      &:focus {
        color: ${props => props.theme.color.primary};
      }

      ${largeUp} {
        font-size: 1rem;
        padding: 17px 0;

        &:active,
        &:focus,
        &:hover {
          color: ${props => props.theme.color.lightBlue};
        }
      }
    }

    .phone-number {
      display: block;
      margin: 0;
      color: ${props => props.theme.color.white};
      text-align: center;

      ${largeUp} {
        padding: 15px 0;
        font-size: 1.1rem;
        font-weight: 600;
        transition: all 0.5s ease-out;
        transition-delay: 0.2s;
      }
    }

    ${largeUp} {
      display: flex;
      flex-direction: row;
      padding: 0;
    }
  }
  
  ${mediumDown} {
    &.opened {
      height: 100%;
      background-color: ${props => props.theme.color.darkGrey};
      transition: all 0.3s ease-in, background 0.5s ease-in;
      transition-delay: 0.25s;

      .hamburger-container {
        transform: rotate(90deg);

        .bar {
          transition: all 0.4s;
          transition-delay: 0.2s;

          &.topBar { transform: translateY(0) rotate(45deg); }
          &.midBar { transform: scale(0); }
          &.btmBar { transform: translateY(-6px) rotate(-45deg); }
        }
      }

      .menu-item {
        transform: translateY(0px);
        opacity: 1;

        &:nth-of-type(1) { transition-delay: 0.3s; }
        &:nth-of-type(2) { transition-delay: 0.4s; }
        &:nth-of-type(3) { transition-delay: 0.5s; }
        &:nth-of-type(4) { transition-delay: 0.6s; }
        &:nth-of-type(5) { transition-delay: 0.7s; }
        &:nth-of-type(6) { transition-delay: 0.8s; }
      }
    }
  }

  ${largeUp} {
    display: flex;
    justify-content: space-between;
    height: auto;
    padding: ${props => props.theme.space.betweenRows} ${props => props.theme.space.betweenColumns};
  }
`

// PropTypes
const propTypes = {
  hasBanner: PropTypes.bool,
  hasHero: PropTypes.bool,
  phoneNumber: PropTypes.string.isRequired,
}

const Header = ({
  hasBanner,
  hasHero,
  phoneNumber,
}) => {
  const [breakpoints] = useContext(BreakpointContext)
  const haveScrolledPast = useScrollPast(0)
  const [hamburgerClass, setHamburgerClass] = useState('closed')
  const hamburgerHandler = () => {
    if (hamburgerClass === 'opened')
      setHamburgerClass('closed')
    else
      setHamburgerClass('opened')
  }

  let hasScrolledPast
  if (hasHero)
    hasScrolledPast = haveScrolledPast ? 'has-scrolled' : 'not-scrolled'
  else
    hasScrolledPast = 'has-scrolled'

  return (
    <HeaderStyles
      className={`${hamburgerClass} ${hasScrolledPast}`}
      data-banner={Boolean(!haveScrolledPast && hasBanner)}
      data-hero={hasHero}
    >
      <div className='header-partial'>
        <div className='logo-wrapper'>
          <Link to='/'>
            <img
              alt='Community leadership builders logo.'
              className='logo'
              src={
                breakpoints.desktop
                  ? '/img/community-leadership-logo.png'
                  : '/img/community-leadership-logo-icon.png'
              }
            />
            <span>Community Leadership Builders</span>
          </Link>
        </div>

        <div className='hamburger-container'>
          <button
            className='hamburger'
            onClick={hamburgerHandler}
          >
            <span className='bar topBar' />
            <span className='bar midBar' />
            <span className='bar btmBar' />
          </button>
        </div>
      </div>
      <nav>
        <ul className='menu'>
          {headerData.map((link, index) => {
            const {
              location,
              title,
            } = link

            return (
              <li
                className='menu-item'
                key={`header-link-${index}`}
              >
                <Link to={location}>{title}</Link>
              </li>
            )
          })}
          <li className='menu-item phone-number-container'>
            {
              breakpoints.desktop
                ? <p className='phone-number'>{phoneNumber}</p>
                : (
                  <a
                    className='button primary phone-number'
                    href={`tel:${phoneNumber}`}
                  >
                    {phoneNumber}
                  </a>
                )
            }
          </li>
        </ul>
      </nav>
    </HeaderStyles>
  )
}

Header.propTypes = propTypes
export default Header
