export default {
  color: {
    black: '#231F20', // Raisin Black
    darkBlue: '#1B4354', // Charcoal
    darkGrey: '#3F3B3C', // Jet
    fail: '#BE1E2D', // Upsdell Red
    grey: '#5A5758', // Davys Grey
    lightBlue: '#80D7F7', // Baby Blue
    lightGrey: '#918F90', // Spanish Gray
    primary: '#00AEEF', // Cyan Process
    secondary: '#126788', // Blue Sapphire
    success: '#009344', // Spanish Green
    warn: '#F8C723', // Saffron
    white: '#FFF', // White
  },
  font: {
    cardo: "'Cardo', serif",
    montserrat: "'Montserrat', sans-serif",
  },
  space: {
    all: '1rem',
    betweenColumns: '1.5rem',
    betweenRows: '1rem',
    betweenSections: '8rem',
  },
}
