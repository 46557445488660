// Modules
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import React from 'react'
import { ThemeProvider } from 'emotion-theming'
import { withPrefix } from 'gatsby'

// Components
import AdBanner from './AdBanner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from './Footer'
import Header from './Header'
import ScrollToTop from 'react-scroll-to-top'
import useSiteMetadata from './SiteMetadata'

// Context
import { BreakpointProvider } from 'Context/breakpointContext'

// Data
import adBannerData from 'data/cms-data/ad-banner'
import sitePhoneNumber from 'data/cms-data/site-phone-number'

// Scripts
import AppStyles from 'scripts/AppStyles'
import theme from 'scripts/theme'

// Styles
import 'styles/normalize.scss'
import 'styles/typography.scss'

// PropTypes
const propTypes = {
  children: PropTypes.node,
  hasNoHero: PropTypes.bool,
}

const Layout = ({
  children,
  hasNoHero,
}) => {
  const {
    title,
    description,
    siteUrl,
  } = useSiteMetadata()

  const { phoneNumber } = sitePhoneNumber
  const { isBanner } = adBannerData

  let canonical = siteUrl

  if (typeof window !== 'undefined') {
    let currentURL = window.location.href

    if (currentURL.includes('?'))
      [currentURL] = currentURL.split('?')

    canonical = currentURL
  }

  /* eslint-disable react/jsx-max-props-per-line -- easier to read the meta data on one line each */
  return (
    <div>
      <Helmet>
        <html lang='en' />

        <meta content='text/html; charset=utf-8' httpEquiv='Content-Type' />
        <meta content='width=device-width, initial-scale=0.86, maximum-scale=3.0, minimum-scale=0.86' name='viewport' />
        <meta content='noindex nofollow' name='robots' />

        <title>{title}</title>
        <meta content={title} property='og:title' />

        <meta content={description} name='description' />
        <meta content={description} property='og:description' />

        <meta content='Community Leadership Builders' property='og:site_name' />
        <meta content='business.business' property='og:type' />
        <meta content='/' property='og:url' />

        <meta content='#233d4d' name='theme-color' />
        <meta content='#233d4d' name='msapplication-TileColor' />
        <meta content={`${withPrefix('/')}mstile-144x144.png`} name='msapplication-TileImage' />
        <meta content={`${withPrefix('/')}browserconfig.xml`} name='msapplication-config' />

        <link href={canonical} rel='canonical' />

        <link href={`${withPrefix('/')}favicon.ico`} rel='shortcut icon' />
        <link href={`${withPrefix('/')}favicon-16x16.png`} rel='icon' sizes='16x16' type='image/png' />
        <link href={`${withPrefix('/')}favicon-32x32.png`} rel='icon' sizes='32x32' type='image/png' />

        <link color='#00AEEF' href={`${withPrefix('/')}safari-pinned-tab.svg`} rel='mask-icon' />
        <link href={`${withPrefix('/')}apple-touch-icon-57x57.png`} rel='apple-touch-icon' sizes='57x57' />
        <link href={`${withPrefix('/')}apple-touch-icon-60x60.png`} rel='apple-touch-icon' sizes='60x60' />
        <link href={`${withPrefix('/')}apple-touch-icon-72x72.png`} rel='apple-touch-icon' sizes='72x72' />
        <link href={`${withPrefix('/')}apple-touch-icon-76x76.png`} rel='apple-touch-icon' sizes='76x76' />
        <link href={`${withPrefix('/')}apple-touch-icon-114x114.png`} rel='apple-touch-icon' sizes='114x114' />
        <link href={`${withPrefix('/')}apple-touch-icon-120x120.png`} rel='apple-touch-icon' sizes='120x120' />
        <link href={`${withPrefix('/')}apple-touch-icon-144x144.png`} rel='apple-touch-icon' sizes='144x144' />
        <link href={`${withPrefix('/')}apple-touch-icon-152x152.png`} rel='apple-touch-icon' sizes='152x152' />
        <link href={`${withPrefix('/')}apple-touch-icon-180x180.png`} rel='apple-touch-icon' sizes='180x180' />

        <link href={`${withPrefix('/')}site.webmanifest`} rel='manifest' />
        <link href={`${withPrefix('/')}android-chrome-192x192.png`} rel='icon' sizes='192x192' type='image/png' />
        <link href={`${withPrefix('/')}android-chrome-256x256.png`} rel='icon' sizes='256x256' type='image/png' />
      </Helmet>
      <ThemeProvider theme={theme}>
        <BreakpointProvider>
          {isBanner && <AdBanner data={adBannerData} />}
          <AppStyles>
            {/* <Header
              hasBanner={isBanner}
              hasHero={Boolean(!hasNoHero)}
              phoneNumber={phoneNumber}
            />
            <main className={hasNoHero && 'no-hero'}>
              {children}
            </main>
            <Footer phoneNumber={phoneNumber} />
            <ScrollToTop
              smooth
              component={(
                <span
                  style={{
                    alignItems: 'center',
                    backgroundColor: theme.color.white,
                    borderRadius: '1rem',
                    color: theme.color.grey,
                    display: 'flex',
                    fontSize: '2rem',
                    height: '25px',
                    justifyContent: 'center',
                    width: '25px',
                  }}
                >
                  <FontAwesomeIcon icon={faArrowCircleUp} />
                </span>
              )}
              style={{
                backgroundColor: 'transparent',
                borderRadius: '1rem',
                boxShadow: 'none',
                height: '36px',
                width: '36px',
              }}
            /> */}

            <div
              style={{
                alignItems: 'center',
                backgroundColor: theme.color.black,
                display: 'flex',
                height: '100vh',
                justifyContent: 'center',
                width: '100vw',
              }}
            >
              <h1 style={{ color: theme.color.white }}>Website Coming Soon</h1>
            </div>
          </AppStyles>
        </BreakpointProvider>
      </ThemeProvider>
    </div>
  )
  /* eslint-enable react/jsx-max-props-per-line -- easier to read the meta data on one line each */
}

Layout.propTypes = propTypes
export default Layout
