import navData from './cms-data/header.json'

export default [
  {
    location: '/',
    title: navData?.navigationLinks?.home
      ? navData.navigationLinks.home
      : 'Home',
  },
  {
    location: '/youth',
    title: navData?.navigationLinks?.youth
      ? navData.navigationLinks.youth
      : 'Youth',
  },
  {
    location: '/parenting',
    title: navData?.navigationLinks?.parenting
      ? navData.navigationLinks.parenting
      : 'Parenting',
  },
  {
    location: '/leadership',
    title: navData?.navigationLinks?.leadership
      ? navData.navigationLinks.leadership
      : 'Leadership',
  },
  {
    location: '/contact',
    title: navData?.navigationLinks?.contact
      ? navData.navigationLinks.contact
      : 'Contact',
  },
  {
    location: '/blog',
    title: navData?.navigationLinks?.blog
      ? navData.navigationLinks.blog
      : 'Blog',
  },
]
