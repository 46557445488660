import footerNav from './cms-data/footer.json'

export default {
  companyLinkGroup: {
    links: [
      {
        location: '/contact',
        title: footerNav?.footerLinks?.contact
          ? footerNav.footerLinks.contact
          : 'Contact Us',
      },
      {
        location: '/blog',
        title: footerNav?.footerLinks?.blog
          ? footerNav.footerLinks.blog
          : 'Blog',
      },
      {
        location: '/sitemap',
        title: footerNav?.footerLinks?.sitemap
          ? footerNav.footerLinks.sitemap
          : 'Sitemap',
      },
    ],
    title: footerNav?.footerLinks?.companyLinksHeader
      ? footerNav.footerLinks.companyLinksHeader
      : 'Company',
  },
  programLinkGroup: {
    links: [
      {
        location: '/youth',
        title: footerNav?.footerLinks?.youth
          ? footerNav.footerLinks.youth
          : 'Youth',
      },
      {
        location: '/parenting',
        title: footerNav?.footerLinks?.parenting
          ? footerNav.footerLinks.parenting
          : 'Parenting',
      },
      {
        location: '/leadership',
        title: footerNav?.footerLinks?.youth
          ? footerNav.footerLinks.leadership
          : 'Leadership',
      },
    ],
    title: footerNav?.footerLinks?.programLinksHeader
      ? footerNav.footerLinks.programLinksHeader
      : 'Programs',
  },
  seals: { ...footerNav.seals },
  socialMediaLinks: { ...footerNav.socialMediaLinks },
}
